export const colors = {
  tan: '#ddd9d0',
  purple: '#662D8C',
  lightPurple: '#B296C5',
  brightPurple: '#773DBD',
  violet: '#5E0440',
  orange: '#FA6666',
  lightOrange: '#FF6C0E',
  blue: '#2672CE',
  pink: '#F090C3',
  grey: '#C4C4C4',
}
