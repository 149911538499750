import styled from 'styled-components'
import { media } from '@id-purchase-funnel-monorepo/shared'

export const H1 = styled.h1`
  font-family: Cooper, serif;

  ${media.mobile`
    font-size: 33px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 52px;
    line-height: 68px;
  `}
`

export const H2 = styled.h2`
  font-family: Cooper, serif;

  ${media.mobile`
    font-size: 24px;
    line-height: 32px;
  `}
  ${media.tablet`
    font-size: 34px;
    line-height: 40px;
  `}
`

export const H3 = styled.h3`
  font-size: 18px;
  line-height: 24px;
`

export const LargeP = styled.p`
  ${media.mobile`
    font-size: 16px;
    line-height: 20px;
  `}
  ${media.tablet`
    font-size: 25px;
    line-height: 32px;
  `}
`

export const P = styled.p`
  font-size: 16px;
  line-height: 24px;
`

export const SmallP = styled.p`
  font-size: 14px;
  line-height: 16px;
`
