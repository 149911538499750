import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { SharedFooter, media } from '@id-purchase-funnel-monorepo/shared'
import { SmallP, P } from './shared/text'

const Container = styled.footer`
  width: 100%;

  background-color: transparent;
  color: ${(props) => props.theme.colors.purple};
`

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;

  ${media.mobile`
    height: ${(props) => (props.hidePrevious ? 200 : 250)}px;
  `}
  ${media.desktop`
    height: ${(props) => (props.hidePrevious ? 100 : 150)}px;
  `}
`

const PrevLink = styled(P)`
  font-family: Beacon-Bold, sans-serif;
  cursor: pointer;
  font-size: 16px;

  position: absolute;
  transform: translate(-50%, 0);

  top: 40px;
  left: 50%;
  z-index: 10;

  &:hover {
    font-weight: 900;
    color: ${(props) => props.theme.colors.orange};
  }
`

const LegalTermsContainer = styled.div`
  width: 90%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  ${media.mobile`
    bottom: 40px;
    max-width: 70%;
  `}
  ${media.tablet`
    bottom: 17px;
    max-width: 100%;
  `}


  & a {
    font-family: Beacon-Bold, sans-serif;
    color: ${(props) => props.theme.colors.purple};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.orange};
    }
  }
`

interface FooterInterface {
  currentPath: string
  bg: string
  textColor: string
}

export default ({ currentPath, bg, textColor }: FooterInterface) => {
  const noPrev = ['/offer/user-information', '/offer/confirm-order']
  const hidePrevious = noPrev.some((pathname) => pathname === currentPath)

  const LegalTerms = () => (
    <SmallP>
      Click here to view our{' '}
      <a
        href="https://www.eatyourgrummies.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        href="https://www.eatyourgrummies.com/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
      .
    </SmallP>
  )

  return (
    <SharedFooter>
      <Container bg={bg} textColor={textColor}>
        <ContentWrapper hidePrevious={hidePrevious}>
          {!hidePrevious && (
            <PrevLink onClick={() => navigate(-1)}>← Back</PrevLink>
          )}
          <LegalTermsContainer>
            <LegalTerms />
          </LegalTermsContainer>
        </ContentWrapper>
      </Container>
    </SharedFooter>
  )
}
