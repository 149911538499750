import React from 'react'
import styled from 'styled-components'

import { media } from '@id-purchase-funnel-monorepo/shared'

export const BasicButton = styled('button')`
  cursor: pointer;

  font-family: Cooper, sans-serif !important;
  line-height: 1;

  transition: 200ms all ease;
  width: fit-content;
  outline: none;

  background-color: ${(props) => props.theme.colors.purple};
  border: 2px solid ${(props) => props.theme.colors.purple};
  border-radius: 20px;
  color: #fff;

  ${media.mobile`
    min-width: 264px;
    font-size: 25px;
    height: 68px;
    padding: 0 17px;
  `}
  ${media.tablet`
    min-width: 316px;
    font-size: 34px;
    height: 72px;
    padding: 0 22px;
  `}
  ${media.desktop`
    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.colors.purple};
    }
  `}
`

interface ButtonProps {
  copy: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  copy,
  onClick,
  type,
  className,
}) => (
  <BasicButton type={type || 'button'} onClick={onClick} className={className}>
    {copy}
  </BasicButton>
)
