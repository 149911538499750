import { createGlobalStyle } from 'styled-components'
import { media } from '@id-purchase-funnel-monorepo/shared'

import Cooper from '../assets/fonts/cooperl.ttf'
import Beacon from '../assets/fonts/BeaconDA-Regular.otf'
import BeaconMedium from '../assets/fonts/BeaconDA-Medium.otf'
import BeaconBold from '../assets/fonts/BeaconDA-Bold.otf'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Cooper';
    src: url(${Cooper});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Beacon';
    src: url(${Beacon});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Beacon-Medium';
    src: url(${BeaconMedium});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Beacon-Bold';
    src: url(${BeaconBold});
    font-style: normal;
    font-display: block;
    font-weight: 400;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: Beacon, Helvetica, sans-serif;
    color: #662D8C;
  }

  form {
    margin: 0 auto 0 auto;
  }

  input, select {
    height: 60px;
    width: 100%;
    border-radius: 15px;
    padding: 0 17px;
    color: #662D8C !important;
    font-size: 19px;
    line-height: 1;
    background-color: #fff;
  }

  input::placeholder {
    color: #999999;
  }

  #text-input-wrapper, #select-input-wrapper {
    background: linear-gradient(90deg, #21A0F8 0%, #52D9BF 37.61%, #F090C3 67.3%, #FA6666 100%);
    padding: 4px;
    border-radius: 18px;
    height: 68px;
  }

  #text-input-wrapper.error-input-wrapper {
    background: #FA6666 !important;
  }

  textarea {
    width: 100%;
    height: fit-content;
    min-height: 100px;
    border-radius: 12px;
    padding: 12px 20px;
    border: 1px solid transparent;
    color: #662D8C;
    font-size: 14px;
    margin: 40px 0 0 0;
  }

  p.error-text {
    font-family: Beacon-Bold, Helvetica, sans-serif;
    color: #FA6666;
    font-size: 10px;
    line-height: 18px;
    text-align: left;
    padding-left: 23px;
    width: 100%;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: 400 !important;
    margin: 0;
    padding: 0;
  }

  select {
    width: 100%;
  }

  #select-input-wrapper {
    width: 100%;
    cursor: pointer;
  }

  #select-input-wrapper::after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: #000;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    margin: -2px 0 0 -10px;
    z-index: 12;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
      position: relative;
      padding: 0 0 0 36px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #999999;
      border-radius: 100%;
      background: transparent;
  }

  [type="radio"]:checked + label:before {
    background: linear-gradient(133.07deg, #21A0F8 8.73%, #52D9BF 38.95%, #F090C3 67.69%, #FA6666 89.31%);
    border: 1px solid #662D8C;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: linear-gradient(133.07deg, #21A0F8 8.73%, #52D9BF 38.95%, #F090C3 67.69%, #FA6666 89.31%);
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

  .hide-mobile {
    ${media.mobile`
      display: none;
    `}
    ${media.desktop`
      display: initial;
    `}
  }

  .hide-desktop {
    ${media.desktop`
      display: none;
    `}
  }

`

export default GlobalStyle
