import React from 'react'
import styled, { keyframes } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import Img from 'gatsby-image'

import { PrimaryLayout, media } from '@id-purchase-funnel-monorepo/shared'

import Navbar from './Navbar'
import Footer from './Footer'
import BrandStyle from './BrandStyle'

import { colors } from '../config/theme'

import GrummyIcon from '../assets/img/grummy.svg'

const fadeInAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const BlobWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;

  position: relative;
  overflow: hidden;
`

const LayoutGrid = styled.div`
  animation: ${fadeInAnimation} 2s;

  height: 100%;
  width: 100%;

  display: grid;
  grid-template-rows: auto 1fr auto;
`

const AbsoluteGradient = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100vw;

  background: linear-gradient(
    180.05deg,
    rgba(236, 142, 142, 0.35) -17.11%,
    rgba(250, 102, 102, 0) 42.2%
  );

  ${media.mobile`
    height: 100%;
  `}
  ${media.tablet`
    height: 568px;
  `}
`

const BlobWrapperBase = styled.div`
  position: absolute;
  z-index: -1;
`

const TopPurple = styled(BlobWrapperBase)`
  top: 0;

  ${media.mobile`
    width: 100vw;
    right: -13vw;
  `}
  ${media.desktop`
    width: 52vw;
    right: auto;
    left: 30vw;
    max-width: 500px;
  `}
`

const RightOrange = styled(BlobWrapperBase)`
  ${media.mobile`
    width: 35vw;
    bottom: -130px;
    right: 0;
  `}
  ${media.desktop`
    width: 20vw;
    max-width: 250px;
    bottom: auto;
    top: 12vh;
    right: -50px;
  `}
`

const BottomPurple = styled(BlobWrapperBase)`
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
    width: 30vw;
    bottom: 0;
    right: 2vw;
  `}
`

const BottomGreen = styled(BlobWrapperBase)`
  ${media.mobile`
    width: 140vw;
    bottom: -200px;
    left: -42vw;
    transform: rotate(95.79deg);
  `}
  ${media.desktop`
    width: 40vw;
    bottom: 0;
    left: 0;
    transform: rotate(0deg);
  `}
`

const LeftPurple = styled(BlobWrapperBase)`
  ${media.mobile`
    width: 40vw;
    top: 30vh;
    left: -23vw;
    transform: rotate(-15.79deg);
  `}
  ${media.desktop`
    width: 16vw;
    top: 23vh;
    left: -50px;
    transform: rotate(0deg);
  `}
`

const GrummyImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  ${media.mobile`
    width: 28px;
  `}
  ${media.desktop`
    width: 47px;
  `}
`

const query = graphql`
  query {
    topPurple: file(relativePath: { regex: "images/blobs/top-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rightOrange: file(
      relativePath: { regex: "images/blobs/right-orange.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomPurple: file(
      relativePath: { regex: "images/blobs/bottom-purple.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomGreen: file(
      relativePath: { regex: "images/blobs/bottom-green.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    leftPurple: file(relativePath: { regex: "images/blobs/left-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface LayoutInterface {
  location: any
  isSorry?: boolean
  bg?: string
  textColor?: string
}

const Layout: React.FC<LayoutInterface> = (props) => {
  const {
    topPurple,
    rightOrange,
    bottomPurple,
    bottomGreen,
    leftPurple,
  } = useStaticQuery(query)

  const { location, bg, textColor, children, isSorry } = props

  const pathname = location.pathname

  const noNav = ['/', '/offer', '/offer/']
  const hideNav = noNav.some((route) => route === pathname)

  const noFooter = ['/', '/offer', '/offer/']
  const hideFooter = noFooter.some((route) => route === pathname)

  const noBlobs = ['/', '/offer', '/offer/']
  const hideBlobs = noBlobs.some((route) => route === pathname)

  return (
    <PrimaryLayout
      bg="linear-gradient(180.05deg, rgba(236, 142, 142, 0.35) -17.11%, rgba(250, 102, 102, 0) 42.2% )"
      spinnerColor={colors.purple}
    >
      <BrandStyle />
      <ThemeProvider theme={{ colors }}>
        <BlobWrapper>
          <GrummyImg src={GrummyIcon} aria-hidden={true} />

          <LayoutGrid>
            {!hideNav && <Navbar bg={bg} isSorry={isSorry} />}

            {children}

            {!hideFooter && (
              <Footer bg={bg} textColor={textColor} currentPath={pathname} />
            )}
          </LayoutGrid>

          {!hideBlobs && (
            <>
              <AbsoluteGradient />

              <TopPurple>
                <Img
                  fluid={topPurple.childImageSharp.fluid}
                  aria-hidden={true}
                />
              </TopPurple>
              <RightOrange>
                <Img
                  fluid={rightOrange.childImageSharp.fluid}
                  aria-hidden={true}
                />
              </RightOrange>
              <BottomPurple>
                <Img
                  fluid={bottomPurple.childImageSharp.fluid}
                  aria-hidden={true}
                />
              </BottomPurple>
              <BottomGreen>
                <Img
                  fluid={bottomGreen.childImageSharp.fluid}
                  aria-hidden={true}
                />
              </BottomGreen>
              <LeftPurple>
                <Img
                  fluid={leftPurple.childImageSharp.fluid}
                  aria-hidden={true}
                />
              </LeftPurple>
            </>
          )}
        </BlobWrapper>
      </ThemeProvider>
    </PrimaryLayout>
  )
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Layout
