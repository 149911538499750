import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { SharedNavbar, media } from '@id-purchase-funnel-monorepo/shared'

const Container = styled.div`
  position: relative;

  background-color: transparent;
  width: 100%;
`

const LogoWrapper = styled.div`
  box-sizing: content-box;

  ${media.mobile`
    width: 68px;
    padding-left: 37px;
    margin: 21px 0 63px 0;
  `}
  ${media.desktop`
    width: 111px;
    padding-left: 62px;
    margin: 36px 0 34px 0;
  `}
`

interface StaticQueryInterface {
  logo: {
    childImageSharp: {
      fluid: {
        aspectRatio: number
        sizes: string
        src: string
        srcSet: string
      }
    }
  }
}

export default ({ bg }: { bg: string }) => (
  <StaticQuery
    query={query}
    render={(data: StaticQueryInterface) => {
      return (
        <SharedNavbar>
          <Container bg={bg}>
            <LogoWrapper>
              <Img
                fluid={data.logo.childImageSharp.fluid}
                alt="Grummies logo"
                style={{ width: '100%' }}
              />
            </LogoWrapper>
          </Container>
        </SharedNavbar>
      )
    }}
  />
)

const query = graphql`
  query {
    logo: file(relativePath: { regex: "images/logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 105) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
