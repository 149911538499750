import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import { H1, H2, H3, LargeP, P, SmallP } from './shared/text'
import { BasicButton } from './shared/buttons'

import { media } from '@id-purchase-funnel-monorepo/shared'

const Container = styled.div`
  height: fit-content;
  min-height: 100vh;
  width: 100%;

  background-color: ${(props) => props.theme.colors.purple};
  color: white;

  display: grid;
  place-items: center;
`

const MenuBlob = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;

  ${media.mobile`
    width: 28px;
  `}
  ${media.tablet`
    width: 46px;
  `}
`

const LogoImg = styled.div`
  position: absolute;

  ${media.mobile`
    width: 67px;
    top: 22px;
    left: 36px;
  `}
  ${media.tablet`
    width: 112px;
    top: 36px;
    left: 63px;
  `}
`

const Content = styled.div`
  z-index: 10;
  display: grid;

  width: 100%;
  height: fit-content;

  ${media.mobile`
    padding: 138px 24px 530px 24px;
    place-items: center;
    text-align: center;
    grid-row-gap: 20px;
    white-space: pre-line;
  `}
  ${media.tablet`
    padding: 150px 40px 105px 40px;
  `}
  ${media.desktop`
    place-items: start;
    text-align: left;
    grid-row-gap: 14px;
    white-space: initial;
    padding: 202px 0 105px 85px;
    max-width: 1440px;
    margin: 0 auto;
  `}
`

const Title = styled(H1)`
  color: white;
  margin: 0;

  ${media.mobile`
    font-size: 34px;
    line-height: 40px;
    white-space: nowrap;
  `}
  ${media.tablet`
    font-size: 60px;
    line-height: 68px;
    white-space: normal;
  `}
`

const Subtitle = styled(LargeP)`
  font-family: Cooper, serif;
  font-size: 25px;
  line-height: 32px;
`

const ButtonLink = styled.a`
  z-index: 50;

  width: fit-content;
  text-decoration: none;

  margin-top: 10px;
`

const MaxWidthGroup = styled.div`
  width: 100%;

  text-align: center;

  display: grid;
  justify-items: center;
  grid-row-gap: 15px;

  ${media.tablet`
    max-width: 386px;
  `}
`

const GradientWrapper = styled.div`
  width: 100%;
  height: min-content;

  background: linear-gradient(
    180deg,
    #21a0f8 0%,
    #52d9bf 26.67%,
    #f090c3 58.44%,
    #fa6666 100%
  );
  border-radius: 36px;
  padding: 5px;

  ${media.mobile`
    margin: 29px 0 0 0;
  `}
  ${media.tablet`
    margin: 20px 0 0 0;
  `}
`

const WhiteContainer = styled.div`
  position: relative;

  height: fit-content;

  display: grid;
  place-items: center;
  grid-row-gap: 20px;

  background-color: white;
  border-radius: 32px;

  color: ${(props) => props.theme.colors.purple};
  text-align: center;

  ${media.mobile`
    width: fit-content;
    padding: 18px 30px 10px 30px;
  `}
  ${media.tablet`
    width: 100%;
    padding: 18px 44px 12px 44px;
  `}

  & h3 {
    line-height: 28px;
  }
`

const DiscountCodeContainer = styled.div`
  display: grid;
  place-items: center;

  cursor: pointer;
  transition: 300ms all ease-in-out;

  border: 1px dashed ${(props) => props.theme.colors.purple};
  border-radius: 10px;

  ${media.mobile`
    padding: 7px 8px;
    min-height: 68px;
  `}
  ${media.tablet`
    padding: 5px 7px;
    min-height: 57px;
  `}

  & h2 {
    font-size: 30px;
  }

  &:hover {
    color: white !important;
    background-color: ${(props) => props.theme.colors.orange};
  }
`

const ContactUsP = styled(P)`
  font-size: 18px;
  line-height: 28px;

  ${media.mobile`
    padding: 30px 0;
  `}
  ${media.tablet`
    padding: 0;
  `}
`

const EmailLink = styled.a`
  color: white;
  font-family: Beacon-Bold, sans-serif;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const LifeStyleImage = styled.div`
  z-index: 1;
  right: 0;
  justify-self: end;

  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
    position: fixed;
    transform: translate(0, -50%);
    bottom: auto;
    top: 59%;
    width: 45vw;
    max-width: 600px;
  `}
`

const LifeStyleImageMobile = styled(LifeStyleImage)`
  ${media.mobile`
    display: block;
    position: absolute;
    width: 100vw;
    bottom: 80px;
  `}
  ${media.tablet`
    display: none;
  `}
`

const StyledButton = styled(BasicButton)`
  background-color: white;
  border-color: white;
  color: ${(props) => props.theme.colors.purple};

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 14px;
  place-items: center;

  width: 100%;
  width: 329px;
  height: 69px;

  & p {
    font-size: 25px;
    justify-self: end;
    margin-top: -6px;
  }

  & svg {
    transition: 200ms all ease;
    height: 28px;
    margin-bottom: -11px;
    justify-self: start;

    & path {
      fill: ${(props) => props.theme.colors.purple};
    }
  }

  &:hover {
    color: white;

    & svg path {
      fill: white;
    }
  }
`

const fadeIn = keyframes`
  0%: { opacity: 0 }
  100%: { opacity: 1 }
`

const CopiedConfirmation = styled(SmallP)`
  color: ${(props) => props.theme.colors.orange};
  line-height: 1;
  margin-top: 10px;
  height: 16px;

  & b {
    animation: ${fadeIn} 1s ease;
  }
`

const BlobTopOrange = styled.div`
  position: absolute;

  ${media.mobile`
    top: 0%;
    left: -2%;
    width: 101vw;
  `}
  ${media.desktop`
    top: -5%;
    left: -2%;
    width: 90vw;
  `}
`

const BlobBottomBlue = styled(BlobTopOrange)`
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
    top: auto;
    bottom: 0;
    left: 0;
    width: 270px;
  `}
`

const BlobBottomBlueMobile = styled(BlobBottomBlue)`
  display: block;

  top: 42%;
  left: 0;
  width: 70px;

  ${media.tablet`
    top: 50%;
    width: 140px;
  `}
  ${media.desktop`
    display: none;
  `}
`

const BlobBottomTeal = styled(BlobBottomBlue)`
  ${media.mobile`
    left: 5%;
    width: 87vw;
    display: block;
    top: auto;
    bottom: 0;
  `}
  ${media.tablet`
    top: auto;
    left: 37%;
    width: 500px;
  `}
  ${media.desktop`
    top: auto;
    left: 25%;
    width: 600px;
  `}
`

const float = keyframes`
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
  90% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
`

const BlobTurmeric = styled.div`
  -webkit-animation: ${float} 20s linear infinite both;
  animation: ${float} 20s linear infinite both;

  ${media.mobile`
    position: absolute;
    width: 49px;
    top: 46%;
    right: 5%;
  `}
  ${media.desktop`
    position: fixed;
    width: 78px;
    top: 55%;
    left: 43%;
    right: auto;
  `}
`

const BlobElderberry = styled(BlobTurmeric)`
  -webkit-animation: ${float} 25s linear infinite both;
  animation: ${float} 25s linear infinite both;

  ${media.mobile`
    width: 49px;
    top: 65%;
    left: 5%;
  `}
  ${media.tablet`
    top: 40%;
    left: 2%;
  `}
  ${media.desktop`
    width: 73px;
    top: 26%;
    left: 60%;
  `}
`

const BlobSpirulina = styled(BlobTurmeric)`
  -webkit-animation: ${float} 17s linear infinite both;
  animation: ${float} 17s linear infinite both;

  left: auto;

  ${media.mobile`
    width: 49px;
    top: 70px;
    right: 24px;
  `}
  ${media.desktop`
    width: 81px;
    top: 5%;
    right: 5%;
  `}
`

const AmazonLogoSVG = () => (
  <svg viewBox="0 0 93 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.8624 21.8743C52.4915 25.8016 44.7009 27.8897 37.9959 27.8897C28.5969 27.8897 20.1334 24.4487 13.7288 18.7193C13.2263 18.2702 13.6739 17.6552 14.2803 18.0042C21.1903 21.9887 29.7375 24.3857 38.5619 24.3857C44.5161 24.3857 51.0622 23.1615 57.0828 20.63C57.9895 20.2496 58.7489 21.225 57.8624 21.8743Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.097 19.3461C59.4097 18.4737 55.5548 18.9342 53.8223 19.1402C53.2968 19.2031 53.2159 18.7483 53.6895 18.4193C56.7647 16.2798 61.8035 16.8976 62.3897 17.6127C62.9788 18.3364 62.2338 23.3392 59.352 25.7277C58.9073 26.0938 58.4857 25.8993 58.685 25.413C59.3318 23.8112 60.7842 20.2185 60.097 19.3461Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.9424 3.30504V1.22553C53.9453 0.908028 54.185 0.699219 54.4737 0.699219H63.8814C64.1817 0.699219 64.4243 0.916609 64.4243 1.22267V3.00756C64.4214 3.3079 64.1673 3.69692 63.7168 4.31763L58.8455 11.2083C60.6531 11.1654 62.5676 11.4343 64.2106 12.3496C64.5802 12.5556 64.6813 12.8616 64.7102 13.162V15.3817C64.7102 15.6877 64.3723 16.0395 64.0171 15.8565C61.1209 14.3548 57.2804 14.1889 54.0781 15.8765C53.7518 16.0481 53.4082 15.6992 53.4082 15.3931V13.285C53.4082 12.9475 53.414 12.3697 53.7576 11.8548L59.4028 3.83136H54.4882C54.185 3.8285 53.9453 3.61683 53.9424 3.30504Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6324 16.2878H16.7709C16.4994 16.2706 16.28 16.0676 16.2598 15.8073V1.26213C16.2598 0.970372 16.5052 0.73868 16.8113 0.73868H19.4765C19.7566 0.752982 19.979 0.961791 19.9963 1.22781V3.12711H20.0511C20.7442 1.29074 22.0551 0.432617 23.8165 0.432617C25.6068 0.432617 26.7272 1.29074 27.53 3.12711C28.2259 1.29074 29.7996 0.432617 31.483 0.432617C32.6843 0.432617 33.9952 0.921746 34.7951 2.02586C35.7018 3.25011 35.517 5.02642 35.517 6.5882L35.5141 15.7701C35.5141 16.059 35.2686 16.2935 34.9626 16.2935H32.1039C31.8151 16.2764 31.5899 16.0504 31.5899 15.7701V8.05558C31.5899 7.44346 31.6447 5.91314 31.509 5.33248C31.2953 4.35136 30.6543 4.07677 29.8256 4.07677C29.1297 4.07677 28.4107 4.53729 28.1161 5.26955C27.8216 6.00468 27.8505 7.22893 27.8505 8.05558V15.7644C27.8505 16.0533 27.605 16.2878 27.2989 16.2878H24.4403C24.1515 16.2706 23.9263 16.0447 23.9263 15.7644L23.9234 8.04986C23.9234 6.42802 24.189 4.03958 22.162 4.03958C20.106 4.03958 20.184 6.36509 20.184 8.04986V15.7615C20.184 16.0561 19.9385 16.2878 19.6324 16.2878Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.5223 0.430664C76.7699 0.430664 79.0656 4.04335 79.0656 8.63715C79.0656 13.0765 76.5274 16.5976 72.5223 16.5976C68.3556 16.5976 66.0859 12.985 66.0859 8.48555C66.0859 3.95182 68.3816 0.430664 72.5223 0.430664ZM72.5483 3.40262C70.4375 3.40262 70.3047 6.24872 70.3047 8.02503C70.3047 9.80134 70.2787 13.5971 72.5223 13.5971C74.74 13.5971 74.8468 10.5365 74.8468 8.66862C74.8468 7.44437 74.7948 5.97412 74.4195 4.80994C74.0989 3.79736 73.4579 3.40262 72.5483 3.40262Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.5749 16.2887H81.7248C81.439 16.2715 81.2109 16.0455 81.2109 15.7652L81.2051 1.21148C81.2282 0.945467 81.4679 0.736658 81.7537 0.736658H84.4074C84.6586 0.75096 84.8636 0.916863 84.9156 1.14283V3.36823H84.9705C85.7732 1.37739 86.8936 0.427734 88.8687 0.427734C90.1508 0.427734 91.4069 0.885398 92.2067 2.14397C92.9546 3.30816 92.9546 5.26753 92.9546 6.67485V15.831C92.9228 16.0884 92.689 16.2887 92.406 16.2887H89.5386C89.273 16.2715 89.0593 16.0798 89.0304 15.831V7.93342C89.0304 6.34018 89.2181 4.01468 87.2401 4.01468C86.5442 4.01468 85.9032 4.4752 85.5826 5.17886C85.1813 6.06844 85.1293 6.95517 85.1293 7.93628V15.7652C85.1264 16.057 84.881 16.2887 84.5749 16.2887Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.4473 9.34034C46.4473 10.4445 46.4733 11.3655 45.9102 12.3466C45.454 13.1447 44.7321 13.6338 43.9294 13.6338C42.8321 13.6338 42.1882 12.8043 42.1882 11.5772C42.1882 9.163 44.3741 8.72536 46.4444 8.72536V9.34034H46.4473ZM49.332 16.2539C49.1443 16.4227 48.87 16.4341 48.6563 16.3197C47.7063 15.536 47.5359 15.1756 47.0162 14.4319C45.4453 16.0165 44.3336 16.4913 42.2979 16.4913C39.8897 16.4913 38.0156 15.0182 38.0156 12.072C38.0156 9.77226 39.2746 8.20477 41.0678 7.43818C42.6213 6.76312 44.7899 6.64013 46.4502 6.45706V6.08807C46.4502 5.41302 46.5022 4.61496 46.1008 4.03144C45.7514 3.51085 45.0844 3.29346 44.4953 3.29346C43.4038 3.29346 42.4336 3.84838 42.1939 4.9954C42.1448 5.24997 41.9572 5.50455 41.6973 5.51599L38.9223 5.21851C38.6884 5.16702 38.4285 4.98109 38.495 4.62641C39.1331 1.2969 42.1708 0.290039 44.8938 0.290039C46.2856 0.290039 48.1077 0.659031 49.205 1.70022C50.5968 2.9874 50.4639 4.7065 50.4639 6.58006V10.9965C50.4639 12.3266 51.0212 12.9073 51.5439 13.6224C51.7258 13.8798 51.7691 14.1859 51.5352 14.3746C50.9519 14.8609 49.9124 15.7534 49.3407 16.2568L49.332 16.2539Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.93171 9.34034C8.93171 10.4445 8.95769 11.3655 8.3975 12.3466C7.94416 13.1447 7.21938 13.6338 6.41663 13.6338C5.31935 13.6338 4.67543 12.8043 4.67543 11.5772C4.67543 9.163 6.86132 8.72536 8.93171 8.72536V9.34034ZM11.8164 16.2539C11.6287 16.4227 11.3544 16.4341 11.1407 16.3197C10.1907 15.536 10.0203 15.1756 9.50056 14.4319C7.92972 16.0165 6.818 16.4913 4.78227 16.4913C2.37403 16.4913 0.5 15.0182 0.5 12.072C0.5 9.77226 1.75898 8.20477 3.55216 7.43818C5.10567 6.76312 7.27424 6.64013 8.93171 6.45706V6.08807C8.93171 5.41302 8.98657 4.61496 8.5852 4.03144C8.2358 3.51085 7.56877 3.29346 6.97971 3.29346C5.88821 3.29346 4.91798 3.84838 4.67831 4.9954C4.62923 5.24997 4.44153 5.50455 4.18165 5.51599L1.40958 5.21851C1.17569 5.16702 0.91581 4.98109 0.982224 4.62641C1.61749 1.2969 4.65521 0.290039 7.38108 0.290039C8.77289 0.290039 10.5921 0.659031 11.6922 1.70022C13.084 2.9874 12.9512 4.7065 12.9512 6.58006V10.9965C12.9512 12.3266 13.5085 12.9073 14.0312 13.6224C14.2131 13.8798 14.2564 14.1859 14.0225 14.3746C13.4392 14.8609 12.3997 15.7534 11.8279 16.2568L11.8164 16.2539Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="92.46"
          height="27.6"
          fill="white"
          transform="translate(0.5 0.290039)"
        />
      </clipPath>
    </defs>
  </svg>
)

interface GatsbyImageProps {
  childImageSharp: {
    fluid: {
      aspectRatio: number
      sizes: string
      src: string
      srcSet: string
    }
  }
}

interface GatsbyImgInterface {
  img: GatsbyImageProps
  alt: string
}

const GatsbyImg = ({ img, alt }: GatsbyImgInterface) => (
  <Img
    fluid={img.childImageSharp.fluid}
    style={{ width: '100%', height: '100%' }}
    imgStyle={{ objectFit: 'contain' }}
    alt={alt}
  />
)

interface StaticQueryInterface {
  logo: GatsbyImageProps
  lifestyleImg: GatsbyImageProps
  lifestyleImgMobile: GatsbyImageProps
  menuBlob: GatsbyImageProps
  topOrangeBlob: GatsbyImageProps
  bottomBlueBlob: GatsbyImageProps
  bottomBlueBlobMobile: GatsbyImageProps
  bottomTealBlob: GatsbyImageProps
  turmericBlob: GatsbyImageProps
  elderberryBlob: GatsbyImageProps
  spirulinaBlob: GatsbyImageProps
}

const Homepage = ({
  data,
  location,
}: {
  location: any
  data: StaticQueryInterface
}) => {
  const discountCode = 'GoGrummies25'
  const [copied, setCopied] = useState(false)

  const handleDiscountCopy = () => {
    setCopied(true)

    const elem = document.createElement('textarea')
    elem.value = discountCode
    document.body.appendChild(elem)
    elem.select()
    document.execCommand('copy')
    document.body.removeChild(elem)

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const amazonCtaLink = 'https://www.amazon.com/s?k=grummies&ref=nb_sb_noss_2'

  return (
    <Layout location={location}>
      <Container>
        <Content>
          <MenuBlob>
            <GatsbyImg img={data.menuBlob} alt="White blob" />
          </MenuBlob>
          <LogoImg>
            <GatsbyImg img={data.logo} alt="Grummies logo" />
          </LogoImg>
          <Title>This offer is complete!</Title>
          <Subtitle>
            Hey there! Thanks for being a loyal Grummies customer.
          </Subtitle>
          <MaxWidthGroup>
            <GradientWrapper>
              <WhiteContainer>
                <H3>
                  <b>Bad news:</b>
                  <br />
                  This offer has expired.
                </H3>
                <H3>
                  <b>Good news:</b>
                  <br />
                  Take <b>25% off</b> ANY Grummies product on Amazon with code
                </H3>
                <div>
                  <DiscountCodeContainer onClick={handleDiscountCopy}>
                    <H2>{discountCode}</H2>
                  </DiscountCodeContainer>
                  <CopiedConfirmation>
                    {copied && <b>Copied ✓</b>}
                  </CopiedConfirmation>
                </div>
              </WhiteContainer>
            </GradientWrapper>

            <ContactUsP>
              <b>Questions?</b>
              <br />
              We'd love to help! Email us at{' '}
              <EmailLink href="mailto:support@eatyourgrummies.com">
                Support@EatYourGrummies.com
              </EmailLink>
            </ContactUsP>

            <ButtonLink
              href={amazonCtaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledButton>
                <Subtitle>Shop on</Subtitle> <AmazonLogoSVG />
              </StyledButton>
            </ButtonLink>
          </MaxWidthGroup>
        </Content>
        <LifeStyleImage onClick={handleDiscountCopy}>
          <GatsbyImg
            img={data.lifestyleImg}
            alt="Woman hugging Grummies bottles"
          />
        </LifeStyleImage>
        <LifeStyleImageMobile onClick={handleDiscountCopy}>
          <GatsbyImg
            img={data.lifestyleImgMobile}
            alt="Woman hugging Grummies bottles"
          />
        </LifeStyleImageMobile>
      </Container>

      <BlobTopOrange>
        <GatsbyImg img={data.topOrangeBlob} alt="Orange blob" />
      </BlobTopOrange>
      <BlobBottomBlue>
        <GatsbyImg img={data.bottomBlueBlob} alt="Blue blob" />
      </BlobBottomBlue>
      <BlobBottomBlueMobile>
        <GatsbyImg img={data.bottomBlueBlobMobile} alt="Blue blob" />
      </BlobBottomBlueMobile>
      <BlobBottomTeal>
        <GatsbyImg img={data.bottomTealBlob} alt="Blue blob" />
      </BlobBottomTeal>

      <BlobTurmeric>
        <GatsbyImg img={data.turmericBlob} alt="Yellow blob" />
      </BlobTurmeric>
      <BlobElderberry>
        <GatsbyImg img={data.elderberryBlob} alt="Purple blob" />
      </BlobElderberry>
      <BlobSpirulina>
        <GatsbyImg img={data.spirulinaBlob} alt="Green blob" />
      </BlobSpirulina>
    </Layout>
  )
}

export default ({ location }) => (
  <StaticQuery
    query={query}
    render={(data: StaticQueryInterface) => (
      <Homepage data={data} location={location} />
    )}
  />
)

const query = graphql`
  query {
    logo: file(relativePath: { regex: "images/paused/logo-white.png/" }) {
      childImageSharp {
        fluid(maxWidth: 128) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lifestyleImg: file(
      relativePath: { regex: "images/paused/lifestyle-image.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lifestyleImgMobile: file(
      relativePath: { regex: "images/paused/lifestyle-image-mobile.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    menuBlob: file(
      relativePath: { regex: "images/paused/menu-blob-white.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 60) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    topOrangeBlob: file(
      relativePath: { regex: "images/paused/top-orange-blob.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomBlueBlob: file(
      relativePath: { regex: "images/paused/bottom-blue-blob.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomBlueBlobMobile: file(
      relativePath: { regex: "images/paused/bottom-blue-blob-mobile.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomTealBlob: file(
      relativePath: { regex: "images/paused/bottom-teal-blob.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmericBlob: file(
      relativePath: { regex: "images/paused/turmeric-blob.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elderberryBlob: file(
      relativePath: { regex: "images/paused/elderberry-blob.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    spirulinaBlob: file(
      relativePath: { regex: "images/paused/spirulina-blob.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
